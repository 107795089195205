import React, {useEffect, useMemo, useRef, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useDispatch, useSelector} from 'react-redux';
import {reset as resetCountry, setList as setCountryList} from '../redux/countrySlice';
import {setCurrent as setCountry} from '../redux/countrySlice';
import {
    reset as resetLayers,
    setGeneral as setLayersGeneral,
    setSearch as setLayersSearch,
    setTypes as setLayersTypes,
} from '../redux/layersSlice';
import {
    setFilters as setUserFilters,
    setLayers as setUserLayers,
    setModes as setUserModes,
    setSelection as setUserSelection,
    setSubstation as setUserSubstation,
} from '../redux/userSlice';
import L from 'leaflet';
import {LayerGroup, LayersControl, Marker, Pane, Polygon, ScaleControl, TileLayer, WMSTileLayer} from 'react-leaflet';
import {useMap} from 'react-leaflet/hooks';
import {Copy as IconCopy} from 'react-bootstrap-icons';
import {
    COUNTRY_CENTROIDS,
    CountrySelector,
    CustomActions,
    DataLayers,
    Geoman,
    PolylineMeasure,
    Profile,
    Project,
    Search,
    TileLayerWithHeader
} from '.';
import {alertService, layerService, operatorService} from '../services';
import {
    availableModes,
    bindMetadataPopupActions,
    copyDeep,
    copyToClipboard,
    createMarkerPopup,
    createMetadataPopup,
    getCenter,
    getCoordinatesForGeometry,
    getGeoJsonFromElements,
    getLayerSettings,
    getMetadataPopup,
    getStyle,
    getUserProfile,
    inverseCoordinates,
    isObject,
    isObjectEmpty,
    markerIconInstallation,
    markerIconPin,
    markerIconSubstation,
    METADATA_POPUP_OPTIONS,
    MODE_DEFAULT,
    MODE_GRAND_COMPTE,
    MODE_PROSPECTION,
    resetFeatureStyle,
} from '../utils';

export function Map() {
    const dispatch = useDispatch();
    const map = useMap();

    // avoid re-creation of WMTS params object on every re-render and thus a re-render of the layer
    const wmtsParamsGeoportail = useMemo(() => ({
        'format': 'image/png',
        'layer': 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS',
        'request': 'GetTile',
        'service': 'wmts',
        'style': 'normal',
        'tilematrixset': 'PM_0_19',
        'transparent': true,
        'version': '1.0.0',
    }), []);
    const wmtsParamsGeorisques = useMemo(() => ({
        'format': 'image/png',
        'layers': ['GEORISQUES_SERVICES'], // ["CAVITE_LOCALISEE"]
        'transparent': true,
    }), []);
    const wmtsParamsLansstyrelsen = useMemo(() => ({
        'format': 'image/png',
        'height': 512,
        'layers': 'text', // granser
        'styles': '', // morkbakgrund
        'transparent': true,
        'width': 512,
    }), []);
    const wmtsParamsLantmateriet = useMemo(() => ({
        'format': 'image/png',
        'height': 512,
        'layers': 'granser',
        'styles': 'registerkarta',
        'transparent': true,
        'width': 512,
    }), []);

    const country = useSelector(state => state.country.value.current);
    const layersSearch = useSelector(state => state.layers.value.search);
    const layersTypes = useSelector(state => state.layers.value.types);
    const userFilters = useSelector(state => state.user.value.filters);
    const userModes = useSelector(state => state.user.value.modes);
    const userLayers = useSelector(state => state.user.value.layers);
    const userSelection = useSelector(state => state.user.value.selection);
    const userSubstation = useSelector(state => state.user.value.substation);

    const markerRef = useRef(null);

    const [filtersShow, setFiltersShow] = useState(false);
    const [layerSelectedCoordinates, setLayerSelectedCoordinates] = useState(null);
    const [layerSelectedPlots, setLayerSelectedPlots] = useState(null);
    const [layerSubstationZones, setLayerSubstationZones] = useState(null);
    const [markerLayerGroup, setMarkerLayerGroup] = useState(null);
    const [project, setProject] = useState(null);
    const [profileShow, setProfileShow] = useState(false);
    const [projectShow, setProjectShow] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    // ensure that the latest state is accessed when showing project component's selected plots
    // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    const projectRef = useRef(null);
    projectRef.current = project;

    let popup = null;

    // load available layers
    useEffect(() => {
        loadLayers();

        return () => {
            dispatch(resetCountry());
            dispatch(resetLayers());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reload data layers based on selected country
    useEffect(() => {
        loadLayers();
    }, [country]);

    // listen to right-click to place a marker
    useEffect(() => {
        map.on('contextmenu', e => {
            map.closePopup(); // close any open marker popup
            const latitude = e.latlng.lat.toPrecision(8);
            const longitude = e.latlng.lng.toPrecision(8);
            setLayerSelectedCoordinates([latitude, longitude]);
        });

        // initialize marker layer group
        setMarkerLayerGroup(L.layerGroup().addTo(map));

        return () => {
            map.off('contextmenu');
        };
    }, [map]);

    // clear data when changing modes
    useEffect(() => {
        reset();
    }, [userModes]);

    // react upon user selection
    useEffect(() => {
        if (null !== userSelection) {
            alertService.success(`${userSelection.type} <strong>${userSelection.display_name}</strong> selected`);

            switch (userSelection.type) {
                case 'Coordinates':
                    selectCoordinates();
                    break;
                case 'Installation':
                    selectInstallation()
                    break;
                case 'Municipality':
                    reset();
                    selectMunicipality();
                    break;
                case 'Municipality+':
                    selectMunicipalityAccumulative();
                    break;
                case 'Plot':
                    selectPlot();
                    break;
                case 'Project':
                    reset();
                    selectProject();
                    break;
                case 'Substation':
                    reset();
                    selectSubstation();
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSelection]);

    // get the municipality closest to center and update layers as municipality search
    const getMunicipalityNearCenterAndSearch = center => {
        operatorService.near(layersTypes.municipality._id, center)
            .then(municipalities => {
                if (0 === municipalities.length) {
                    alertService.warn(`No municipality found near <strong>${center[0]},${center[1]}</strong>`);
                    return;
                }

                let showOnlyLayerId = null;
                if (true === [MODE_DEFAULT].includes(userModes[country])) {
                    showOnlyLayerId = '.'; // hide all search data layers , no ID needed since this is a general data layer (substations)
                }
                searchLayersUpdate({
                    'municipality': municipalities.pop(),
                    'substationZone': null,
                }, showOnlyLayerId);

                map.setView(center, 15);
            })
            .finally(() => {
                setSearchIsLoading(false);
            });
    };

    const loadLayers = () => {
        layerService.getAll()
            .then(layers => {
                if (layers === null) {
                    alertService.error('Failed to connect to backend.');
                    return;
                }

                const localLayersGeneral = [];
                const localLayersSearch = [];
                const localLayersTypes = {};
                const countryList = [];
                layers.forEach(layer => {
                    const layerSettings = getLayerSettings(layer);
                    const localLayer = {
                        ...layerSettings,
                        '_id': layer._id,
                        'data': [], // null would stop the layer from showing in layer manager
                        'name': layer.name,
                    };

                    if (country === localLayer.country) {
                        localLayersTypes[localLayer.type] = localLayer; // this potentially overrides layers, but we deliberately expect/want only one per type
                    }

                    if (false === countryList.includes(layerSettings.country)) {
                        countryList.push(layerSettings.country);
                    }

                    if (country === layerSettings.country) {
                        if ('general' === layerSettings.scope) {
                            localLayersGeneral.push(localLayer);
                        } else {
                            localLayersSearch.push(localLayer);
                        }
                    }
                });

                dispatch(setCountryList(countryList.sort()));
                dispatch(setLayersGeneral(localLayersGeneral));
                dispatch(setLayersSearch(localLayersSearch));
                dispatch(setLayersTypes(localLayersTypes));

                // now that we have default styles through layersTypes, set Leaflet's default icon
                L.Marker.prototype.options.icon = markerIconPin(localLayersTypes?.default?.style?.default?.fillColor);

                // init user's profile from localStorage or current values
                let userProfile = getUserProfile();
                const localCountry = userProfile.country || country;
                const localUserLayers = userProfile.layers;
                let localUserModes = userProfile.modes || userModes;
                if (undefined === localUserModes[localCountry]) {
                    localUserModes =  {[localCountry]: MODE_DEFAULT};
                }
                // init user's profile with layers based on current mode's active layers, only store active
                if (false === localCountry in localUserLayers || 0 === localUserLayers[localCountry].length) {
                    localUserLayers[localCountry] = [];
                    const availableModeLayers = availableModes?.[localUserModes?.[localCountry]]?.activeLayers?.[localCountry] || [];
                    layers.forEach(layer => {
                        if (true === availableModeLayers.includes(layer.name)) {
                            localUserLayers[localCountry][layer._id] = layer.name;
                        }
                    });
                }
                dispatch(setCountry(localCountry));
                dispatch(setUserModes(localUserModes));
                dispatch(setUserLayers(localUserLayers));

                // init map center based on active country
                const countryCentroid = COUNTRY_CENTROIDS[localCountry];
                map.setView([countryCentroid.lat, countryCentroid.lng], countryCentroid.zoom);
            });
    };

    const onFeatureSelect = (layer, feature, event, layerRef = null) => {
        const container = L.DomUtil.create('div');
        resetFeatureStyle(layerRef);

        if ('plot' === layer.type) {
            // set active style
            const style = getStyle(layer, feature);
            event.target.setStyle({
                'color': layer.style.active?.color || style.color,
                'fillColor': layer.style.active?.color || style.fillColor,
                'fillOpacity': layer.style.active?.fillOpacity || style.fillOpacity,
                'radius': layer.style.active?.radius || style.radius,
                'weight': layer.style.active?.weight || style.weight,
            });

            // add project buttons
            const plotId = feature.display_name;

            if ('FR' === country) {
                let buttonProjectLabel;
                let buttonProjectOnclick;
                if (true === projectRef.current?.plot_ids?.includes(plotId)) {
                    buttonProjectLabel = 'Remove from project';
                    buttonProjectOnclick = () => {
                        projectRemovePlot(plotId);
                        popup.close();
                    };
                } else {
                    buttonProjectLabel = 'Add to project';
                    buttonProjectOnclick = () => {
                        projectAddPlots([plotId]);
                        popup.close();
                    };
                }

                const buttonProject = L.DomUtil.create('button', 'btn btn-primary btn-sm mb-3', container);
                buttonProject.type = 'button';
                buttonProject.innerHTML = buttonProjectLabel;
                buttonProject.onclick = buttonProjectOnclick;
                }

            const buttonPlotId = L.DomUtil.create('button', 'btn btn-outline-secondary btn-sm mb-3 ms-2', container);
            buttonPlotId.type = 'button';
            buttonPlotId.innerHTML = renderToString(<>
                {plotId}
                <IconCopy className="ms-1"/>
            </>);
            buttonPlotId.onclick = () => {
                copyToClipboard(plotId, () => {
                    popup.close();
                });
            };
        } else if (true === ['municipality', 'substation'].includes(layer.type)) {
            // add search button
            const buttonSearch = L.DomUtil.create('button', 'btn btn-primary btn-sm mb-3', container);
            buttonSearch.type = 'button';
            buttonSearch.innerHTML = 'Search';
            buttonSearch.onclick = () => {
                resetFeatureStyle(layerRef);

                if ('municipality' === layer.type) {
                    dispatch(setUserSelection({
                        '_id': feature.id,
                        'display_name': feature.display_name,
                        'geometry': feature.geometry,
                        'type': 'Municipality+',
                    }));
                } else if ('substation' === layer.type) {
                    const substation = {
                        '_id': layer._id,
                        'display_name': feature.display_name,
                        'geometry': feature.geometry,
                        'metadata': feature.properties,
                        'type': 'Substation',
                    };
                    dispatch(setUserSelection(substation));
                    dispatch(setUserSubstation(substation));
                }

                setSearchInput(feature.display_name);
                popup.close();
            };
        }

        const metadataDiv = L.DomUtil.create('div', null, container);
        metadataDiv.innerHTML = getMetadataPopup(layer.type, feature.display_name, feature.properties, layer.display_fields);
        popup = L.popup(METADATA_POPUP_OPTIONS)
            .setContent(container)
            .setLatLng(event.latlng)
            .on('add', bindMetadataPopupActions)
            .on('remove', () => { // on popup close
                resetFeatureStyle(layerRef);
            })
            .openOn(map);
    };

    const onFeaturesSelect = event => {
        const bounds = event.boxSelectBounds;
        const featureIds = [];
        map.eachLayer(layer => {
            if (layer instanceof L.Polygon &&
                'defaultOptions' in layer &&
                bounds.contains(layer.getBounds())) {
                featureIds.push(layer.feature.display_name);
                layer.setStyle(layersTypes?.plot?.style?.active);
            }
        });

        projectAddPlots(featureIds);
    };

    const onSelectedCoordinates = () => {
        const marker = markerRef.current;
        if (marker != null) {
            marker
                .bindPopup(createMarkerPopup(
                    marker,
                    localUserSelection => dispatch(setUserSelection(localUserSelection)),
                    localSearchInput => setSearchInput(localSearchInput))
                )
                .openPopup();
        }
        copyToClipboard(layerSelectedCoordinates.join(','));
    };

    // @TODO: reset feature style on map upon plot removal
    const projectRemovePlot = id => {
        const updatedSelectedPlotIds = [...projectRef.current.plot_ids];
        const indexPlotId = updatedSelectedPlotIds.indexOf(id);
        if (-1 !== indexPlotId) {
            updatedSelectedPlotIds.splice(indexPlotId, 1);
            setProject({
                ...projectRef.current,
                'plot_ids': updatedSelectedPlotIds,
            });
        }
    };

    const projectAddPlots = newIds => {
        // update project with added plots
        if (null !== projectRef.current) {
            const duplicateIds = [];
            const localPlotIds = projectRef.current.plot_ids ?? [];
            newIds.forEach(newId => {
                if (true === localPlotIds.includes(newId)) {
                    duplicateIds.push(newId);
                } else {
                    localPlotIds.push(newId);
                }
            });

            if (0 < duplicateIds.length) {
                alertService.warn(`The plots <strong>${duplicateIds.join(', ')}</strong> have already been selected`);
            }

            setProject({
                ...projectRef.current,
                'plot_ids': localPlotIds,
            });
        // init project with added plots only
        } else {
            setProject({
                'plot_ids': newIds,
                'substation_code': userSubstation?.display_name,
            });
        }

        setProjectShow(true);
    };

    const selectCoordinates = () => {
        const center = [userSelection.data.latitude, userSelection.data.longitude];
        setLayerSelectedCoordinates(center);
        getMunicipalityNearCenterAndSearch(center);
    };

    const selectInstallation = () => {
        const center = [userSelection.data.geometry.coordinates[1], userSelection.data.geometry.coordinates[0]];
        getMunicipalityNearCenterAndSearch(center);

        // @WARNING: if the layer JBox and IECharge is active, the following marker will be duplicated by the element of that layer
        const layerInstallation = layersSearch.filter(layerSearch => 'installation' === layerSearch.type).pop();
        L.marker(center, {
            'icon': markerIconInstallation(layerInstallation.style.default.fillColor),
        })
            .addTo(markerLayerGroup)
            .bindPopup(createMetadataPopup('installation', userSelection.display_name, userSelection.metadata, layerInstallation.display_fields));
    };

    const selectMunicipality = () => {
        // @WARNING: sometimes the userSelection's geometry doesn't match the selected municipality...
        map.fitBounds(inverseCoordinates(getCoordinatesForGeometry(userSelection.geometry)));

        let showOnlyLayerId = null;
        if (false === [MODE_PROSPECTION].includes(userModes[country])) {
            showOnlyLayerId = '.'; // hide all search data layers , no ID needed since this is a general data layer (substations)
        }

        searchLayersUpdate({
            'municipality': userSelection,
            'substationZone': null,
        }, showOnlyLayerId);
        setSearchIsLoading(false);
    };

    // user runs search on a municipality level through the map
    const selectMunicipalityAccumulative = () => {
        searchLayersUpdate({
            'isDataAccumulate': true,
            'municipality': userSelection,
            'substationZone': null,
        });
    };

    // user selects a plot from search suggestions
    const selectPlot = () => {
        const coordinates = inverseCoordinates(getCoordinatesForGeometry(userSelection.geometry));
        const localLayerSelectedPlots = layerSelectedPlots || [];
        localLayerSelectedPlots.push({
            '_id': userSelection._id,
            'coordinates': coordinates,
            'display_name': userSelection.display_name,
            'metadata': userSelection.metadata,
        });
        setLayerSelectedPlots(localLayerSelectedPlots);

        if (true === [MODE_GRAND_COMPTE, MODE_PROSPECTION].includes(userModes[country])) {
            operatorService.intersect(layersTypes.municipality._id, userSelection._id)
                .then(municipalities => {
                    if (0 === municipalities.length) {
                        alertService.warn(`No municipality interects with plot <strong>${userSelection.display_name}</strong>`);
                        return;
                    }
                    searchLayersUpdate({
                        'municipality': municipalities.pop(),
                        'substationZone': null,
                    }, true);

                    map.fitBounds(coordinates);
                })
                .finally(() => {
                    setSearchIsLoading(false);
                });
        } else {
            map.fitBounds(coordinates);
            setSearchIsLoading(false);
        }
    }

    // user selects a project from search suggestions
    const selectProject = () => {
        if (false === 'name.substation' in userSelection.metadata) {
            alertService.warn(`No substation name found in metadata of project <strong>${userSelection.display_name}</strong>`);
            setSearchIsLoading(false);
            return;
        }

        if (false === 'Plot.Id_JSP' in userSelection.metadata) {
            alertService.warn(`No plots found in metadata of project <strong>${userSelection.display_name}</strong>`);
            setSearchIsLoading(false);
            return;
        }

        const substationCode = userSelection.metadata['Substation.code_JSP'];
        return operatorService.getElement(layersTypes.substationZone._id, {
            'phrase': [{
                'key': 'metadata.substations.code',
                'value': substationCode,
            }],
        })
            .then(substationZone => {
                if (null === substationZone) {
                    alertService.warn(`No substation zone found for code <strong>${substationCode}</strong>`);
                    setSearchIsLoading(false);
                    return;
                }

                searchLayersUpdate({
                    'municipality': null,
                    'substationZone': substationZone,
                });
                map.fitBounds(inverseCoordinates(getCoordinatesForGeometry(substationZone.geometry)));

                // project has no plots, stop here
                let projectPlotIds = userSelection.metadata['Plot.Id_JSP'];
                if (null === projectPlotIds) {
                    alertService.warn(`No plots found for project <strong>${userSelection.display_name}</strong>`);
                    setSearchIsLoading(false);
                    return;
                }

                // prepare parallelization of plot detail queries
                const promises = [];
                projectPlotIds = projectPlotIds.split(',').map(plotId => plotId.trim());
                projectPlotIds.forEach(plotId => {
                    promises.push(
                        operatorService.getElement(layersTypes.plot._id, {
                            'phrase': [{
                                'key': 'display_name',
                                'value': plotId,
                            }],
                        })
                    );
                });

                Promise.all(promises).then(plots => {
                    const localLayerSelectedPlots = [];
                    let coordinates = [];
                    let countSuccessfullyRetrievedPlots = 0;
                    plots.forEach(plot => {
                        if (null !== plot) {
                            countSuccessfullyRetrievedPlots++;

                            const inversedCoordinates = inverseCoordinates(plot.geometry.coordinates[0]);
                            localLayerSelectedPlots.push({
                                'id': plot._id,
                                'coordinates': inversedCoordinates,
                                'display_name': plot.display_name,
                                'metadata': plot.metadata,
                            });
                            coordinates = [
                                ...coordinates,
                                ...inversedCoordinates,
                            ];
                        }
                    });

                    if (projectPlotIds.length !== countSuccessfullyRetrievedPlots) {
                        alertService.warn(`<strong>${countSuccessfullyRetrievedPlots}</strong> of the project's <strong>${projectPlotIds.length}</strong> plots have been matched.`);
                    }

                    setLayerSelectedPlots(localLayerSelectedPlots);
                    setProject({
                        'name': userSelection.display_name,
                        'plot_ids': localLayerSelectedPlots.map(plot => plot.display_name),
                        'substation_code': substationCode,
                    });
                    setSearchIsLoading(false);
                });

                setProjectShow(true);
            });
    };

    const selectSubstation = () => {
        const center = inverseCoordinates([userSelection.geometry.coordinates]).pop();
        const substationCode = userSelection.display_name;
        const title = `Substation ${substationCode}`;
        L.marker(center, {
            'icon': markerIconSubstation(layersTypes.substation.style.default.fillColor),
        })
            .addTo(markerLayerGroup)
            .bindPopup(createMetadataPopup('substation', title, userSelection.metadata, layersTypes.substation.display_fields));

        let showOnlyLayerId = null;
        if (false === [MODE_PROSPECTION].includes(userModes[country])) {
            showOnlyLayerId = '.'; // hide all search data layers , no ID needed since this is a general data layer (substations)
        }

        undefined !== layersTypes.substationZone && operatorService.getElement(layersTypes.substationZone._id, {
            'phrase': [{
                'key': 'metadata.substations.code',
                'value': substationCode,
            }],
        })
            .then(substationZone => {
                if (null !== substationZone) {
                    const zones = [inverseCoordinates(getCoordinatesForGeometry(substationZone.geometry))];
                    if (MODE_PROSPECTION !== userModes[country]) {
                        setLayerSubstationZones(zones);
                    }
                    map.fitBounds(zones);

                    searchLayersUpdate({
                        'municipality': null,
                        'substationZone': substationZone,
                    }, showOnlyLayerId);
                } else {
                    alertService.warn(`No substation zone found for ${substationCode}`);
                }
            })
            .finally(() => {
                setSearchIsLoading(false);
            });
    };

    // update data of one given search data layer
    const searchLayersUpdate = (intersectObject, showOnlyLayerId = null) => {
        const searchLayerCount = layersSearch.length;

        // reset data of inactive layers by storing it in oldLayers, to be pushed to layersSearch later
        const oldLayers = [];
        layersSearch
            .filter(layer => !(country in userLayers && true === Object.keys(userLayers[country]).includes(layer._id)))
            .forEach(layer => {
                oldLayers.push({
                    ...layer,
                    'data': [], // null would stop the layer from showing in layer manager
                });
            });

        // intersect current selection with user's active layers, then update layersSearch including oldLayers
        const updatedLayers = [];
        layersSearch
            .filter(layer => true === country in userLayers && true === Object.keys(userLayers[country]).includes(layer._id))
            .forEach(layer => {
                let areFeaturesHidden = null !== showOnlyLayerId && layer._id !== showOnlyLayerId;

                // only show certain layers in prospection mode
                if (MODE_PROSPECTION === userModes[country] &&
                    'general' !== layer.scope &&
                    false === [layersTypes?.highVoltageLine?._id, layersTypes?.installation?._id, layersTypes?.municipality?._id, layersTypes?.plot?._id, layersTypes?.projectPlot?._id].includes(layer._id)) {
                    areFeaturesHidden = true;
                }

                const onElements = (elements, fields = null) => {
                    alertService.info(`${elements.length} intersecting elements from layer <strong>${layer.name}</strong> loaded`);

                    let data = getGeoJsonFromElements(elements);
                    if (true === intersectObject.isDataAccumulate) {
                        data = [...layer.data, ...data]; // merge data
                    }

                    updatedLayers.push({
                        ...layer,
                        'areFeaturesHidden': areFeaturesHidden,
                        'data': data,
                        'defaultPlotFilters': fields,
                    });

                    if (searchLayerCount === oldLayers.length + updatedLayers.length) {
                        setSearchIsLoading(false);
                    }

                    const updatedLayersSearch = [...oldLayers, ...updatedLayers];
                    dispatch(setLayersSearch(updatedLayersSearch));
                };

                // data search via new (Atlas) endpoint
                if ('plot' === layer.type) {
                    // either a municipality or a substation zone is needed for intersect, avoid a massive query
                    if (null === intersectObject.municipality && null === intersectObject.substationZone) {
                        alertService.warn(`No municipality or substation zone to intersect with layer <strong>${layer.name}</strong>`);
                        return;
                    }

                    const geometry = null !== intersectObject.municipality ? intersectObject.municipality.geometry : intersectObject.substationZone.geometry;
                    const fields = {
                        'equals': [],
                        'geometry': geometry,
                        'phrase': [],
                        'range': [],
                    };
                    if ('FR' === country && null !== intersectObject.substationZone) {
                        fields.equals.push({
                            'key': 'metadata.substations.FLAG_50m_lines10k',
                            'value': true,
                        });
                        fields.phrase.push({
                            'key': 'metadata.substations.code',
                            'value': intersectObject.substationZone.metadata.substations.code,
                        });
                        fields.range.push({
                            'key': 'metadata.substations.NUM_rating',
                            'value': 100,
                        });
                    }

                    // init user filters, this overrides potentially existing filters for that layer
                    const localUserFilters = copyDeep(userFilters);
                    localUserFilters[layer._id] = fields;
                    dispatch(setUserFilters(localUserFilters));

                    operatorService.getElements(layer._id, {}, geometry, {
                        'path': 'metadata.substations',
                        'fields': fields,
                    })
                        .then(elements => onElements(elements, fields));
                // non-plot layers, old intersect
                } else {
                    const elementId = null !== intersectObject.municipality ? intersectObject.municipality._id : intersectObject.substationZone._id;
                    operatorService.intersect(layer._id, elementId)
                        .then(elements => onElements(elements));
                }
            });
    };

    // clear map
    const reset = () => {
        // clear non-search data layers
        setLayerSelectedCoordinates(null);
        setLayerSelectedPlots(null);
        setLayerSubstationZones(null);

        // clear search layers
        if (layersSearch !== null) {
            // strip layer objects back to initial state, i.e. empty data object etc.
            const updatedLayersSearch = layersSearch.map(layer => {
                return {
                    ...layer,
                    'data': [], // null would stop the layer from showing in layer manager
                };
            });

            dispatch(setLayersSearch(updatedLayersSearch));
        }

        // clear markers
        if (markerLayerGroup !== null) {
            markerLayerGroup.clearLayers();
        }

        // close popups
        map.closePopup();
    };

    return (
        <>
            <div id="block-interaction"/>
            <LayersControl position="topright">
                <Pane name="map-osm-greyscale">
                    <LayersControl.BaseLayer checked={false} name="OpenStreetMap Greyscale">
                        <TileLayer
                            attribution="© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-osm">
                    <LayersControl.BaseLayer checked={false} name="OpenStreetMap">
                        <TileLayer
                            attribution="© OpenStreetMap contributors"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-otm">
                    <LayersControl.BaseLayer checked={false} name="OpenTopoMap">
                        <TileLayer
                            attribution="Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)"
                            maxZoom={22}
                            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"/>
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite">
                    <LayersControl.BaseLayer checked={false} name="GoogleMaps Satellite">
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={['mt1', 'mt2', 'mt3']}
                            url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                        />
                        {/* lyrs options, can be accumulated like s,h:
                            h = roads only
                            m = standard roadmap
                            p = terrain
                            r = somehow altered roadmap
                            s = satellite only
                            t = terrain only
                            y = hybrid */}
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite-labels">
                    <LayersControl.BaseLayer checked={true} name="GoogleMaps Satellite Only">
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={['mt1', 'mt2', 'mt3']}
                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                {/*{country === 'SE' && <Pane name="map-lantmateriet">*/}
                {/*        <LayersControl.BaseLayer checked={true} name="Lantmäteriet">*/}
                {/*            <TileLayerWithHeader*/}
                {/*                attribution="© <a href='https://www.lantmateriet.se/en/'>Lantmäteriet</a> Topografisk Webbkarta Visning, CCB"*/}
                {/*                headers={{*/}
                {/*                    'Authorization': 'Bearer %JWT%',*/}
                {/*                }}*/}
                {/*                maxZoom={15}*/}
                {/*                minZoom={4}*/}
                {/*                url={`https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/1.0.0/topowebb/default/3006/{z}/{y}/{x}.png`}*/}
                {/*            />*/}
                {/*        </LayersControl.BaseLayer>*/}
                {/*</Pane>}*/}
                {'FR' === country && <>
                    <Pane name="map-geoportail">
                        <LayersControl.Overlay checked={true} name="Géoportail">
                            <WMSTileLayer
                                attribution={'Géoportail'}
                                maxZoom={22}
                                minZoom={0}
                                opacity={.5}
                                params={wmtsParamsGeoportail}
                                tileSize={256}
                                url={'https://data.geopf.fr/wmts?tilematrix={z}&tilerow={y}&tilecol={x}'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                    <Pane name="map-georisques">
                        <LayersControl.Overlay checked={false} name="Géorisques">
                            <WMSTileLayer
                                attribution={'Géorisques'}
                                maxZoom={22}
                                minZoom={12}
                                opacity={.5}
                                params={wmtsParamsGeorisques}
                                tileSize={512}
                                url={'https://www.georisques.gouv.fr/services?'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                </>}
                {'SE' === country && <>
                    <Pane name="map-länsstyrelsen">
                        <LayersControl.Overlay checked={true} name="Länsstyrelsen">
                            <WMSTileLayer
                                attribution={'Länsstyrelsen'}
                                maxZoom={22}
                                minZoom={0}
                                opacity={1}
                                params={wmtsParamsLansstyrelsen}
                                url={'https://ext-geoportal.lansstyrelsen.se/wmsproxy/wms/fastighet?'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                    <Pane name="map-lantmäteriet">
                        <LayersControl.Overlay checked={true} name="Lantmäteriet">
                            <WMSTileLayer
                                attribution={'Lantmäteriet'}
                                maxZoom={22}
                                minZoom={0}
                                opacity={1}
                                params={wmtsParamsLantmateriet}
                                url={'https://minkarta.lantmateriet.se/map/fastighetsindelning?'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                </>}
                {layerSelectedPlots &&
                    <Pane name="selected-plots">
                        <LayersControl.Overlay checked name="Selected plots">
                            <LayerGroup>
                                {layerSelectedPlots.map((layerSelectedPlot, key) => {
                                    const pathOptions = layersTypes.plot.style.active;
                                    switch (layerSelectedPlot.metadata['Validation.Status']) { // override default values based on status
                                        case 'OK JB':
                                            pathOptions.fillColor = '#84FF00';
                                            break;
                                        case 'OK JB/IE':
                                            pathOptions.fillColor = '#408000';
                                            break;
                                        case 'KO':
                                            pathOptions.fillColor = '#F00';
                                            break;
                                    }
                                    return (<Polygon
                                        eventHandlers={{
                                            'click': event => {
                                                popup = createMetadataPopup('plot', layerSelectedPlot.display_name, layerSelectedPlot.metadata, layersTypes.plot.display_fields);
                                                popup
                                                    .setLatLng(event.latlng)
                                                    .openOn(map);
                                            },
                                        }}
                                        key={key}
                                        pathOptions={pathOptions}
                                        positions={layerSelectedPlot.coordinates}
                                    />);
                                })}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                }
                {layerSubstationZones &&
                    <Pane name="substation-zones">
                        <LayersControl.Overlay checked={true} name="Substation zones">
                            <LayerGroup>
                                {layerSubstationZones.map((layerSubstationZone, key) => (
                                    <Polygon
                                        key={key}
                                        pathOptions={layersTypes?.substationZone?.style?.default}
                                        positions={layerSubstationZone}
                                    />
                                ))}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                }
                {layerSelectedCoordinates &&
                    <Pane name="selected-coordinates">
                        <LayersControl.Overlay checked name="Selected coordinates">
                            <LayerGroup>
                                <Marker
                                    icon={markerIconPin(layersTypes?.default?.style?.default?.fillColor)}
                                    draggable={true}
                                    eventHandlers={{
                                        'add': onSelectedCoordinates,
                                        'click': onSelectedCoordinates,
                                    }}
                                    position={layerSelectedCoordinates}
                                    ref={markerRef}
                                />
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                }
            </LayersControl>
            <div id="panel">
                <Search
                    filtersShow={filtersShow}
                    isLoading={searchIsLoading}
                    profileShow={profileShow}
                    project={project}
                    projectShow={projectShow}
                    searchInput={searchInput}
                    setFiltersShow={setFiltersShow}
                    setIsLoading={setSearchIsLoading}
                    setProfileShow={setProfileShow}
                    setProject={setProject}
                    setProjectShow={setProjectShow}
                    setSearchInput={setSearchInput}
                />
                <DataLayers
                    filtersShow={filtersShow}
                    isGeneral={true}
                    onFeatureSelect={onFeatureSelect}
                    searchIsLoading={searchIsLoading}
                />
                <DataLayers
                    filtersShow={filtersShow}
                    isGeneral={false}
                    onFeatureSelect={onFeatureSelect}
                    onFeaturesSelect={onFeaturesSelect}
                    searchIsLoading={searchIsLoading}
                />
                <Profile
                    setShow={setProfileShow}
                    show={profileShow}
                />
            </div>
            <Project
                project={project}
                removePlot={projectRemovePlot}
                setProject={setProject}
                setShow={setProjectShow}
                show={projectShow}
            />
            <Geoman/>
            <PolylineMeasure/>
            <CustomActions/>
            <ScaleControl imperial={true} position="bottomright"/>
            <CountrySelector setSearchInput={setSearchInput}/>
        </>
    );
}
